<template>
  <div
    :id="`tabpanel-${index}`"
    ref="tabpanelRef"
    role="tabpanel"
    :aria-labelledby="`tab-${index}`"
    class="collection-tabs-item"
    :data-is-visible="tabpanelRefIsVisible"
  >
    <StoryblokComponent :blok="blok" :level="1" tag="div" />
  </div>
</template>

<script setup>
const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  blok: {
    type: Object,
    required: true,
  },
});

const tabpanelRef = ref(null);
const tabpanelRefIsVisible = useElementVisibility(tabpanelRef);
</script>
